.sidebars {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 0;
  overflow-y: auto;
  transition: all 0.5s ease;
  ul,ol {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 10px 0;
    li {
      font-size: 1.2em;

      a {
        opacity: 0.75;
        color: #ccc;
        font-size: 1.2rem;
        transition: opacity 0.1s;

        &:hover, &#active {
          opacity: 1;
          color: #fff;
          text-shadow: 1px 0px 20px white;
          text-decoration: none;
          -webkit-stroke-width: 5.3ps;
          -webkit-stroke-color: #FFFFF;
          -webkit-fill-color: #FFFFFF;
        }
        &:active, &:focus {
          text-decoration: none;
        }
      }
    }
  }
}

.ribbon {
  &-right {
    background-image: var(--rightgod);
    float: right;
  }
  &-left {
    background-image: var(--sidegod);
  }
  overflow-y: hidden;
  overflow-x: hidden;
  width: var(--sidebar-width);
  height: 100%;
  z-index: 1005;

  .bulletins {
    h5 { font-size: 0.8rem; }
    h2.post-list-heading { padding-top: 1rem; }
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 1005;
    border-radius: 5px;
    hr {
      margin: 0 4% 0 4%;
      color: rgba(0,0,0,0.5);
    }
  }
  .pagination {
    ul {
      margin-left: auto;
      margin-right: auto;
      li a {
        padding: 5px 10px 5px 10px;
      }
    }
  }
}

// Sidebar Styles
.emblem-wrapper {
  display: block;
  width: 250px;
  height: 250px;
}
#emblem {
  height: 250px;
  width: 250px;
  position: absolute;
  margin: 0 auto 0 auto;
  z-index: 1030;
  .navbar-toggler {
    display: none;
  }
  img {
    top: 25px;
    left: 25px;
    right: 0;
    bottom: 0;
    position:absolute;
    height: 200px;
    width: 200px;
    transition: all 1s;
    &.toggled {
      position: fixed;
    }
  }
}
