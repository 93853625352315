// Classes in order of appearance
.masthead {
  position: absolute;
  //height: calc(0.75 * var(--logo-height));
  width: 100%;
  //z-index: 1100;
  background-color: transparent;
  border-bottom: none;
  a,p,b,i,h1,h2,h3,h4,h5,h6 {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: #ddd;
    small {
      text-decoration: none;
      color: #fff;
    }
    &:hover {
      text-decoration: none;
      color: #fff;
    }
    &:active, &:focus {
      text-decoration: none;
    }
  }
  div.container {
    position: absolute;
    padding-left: var(--sidebar-width);
    margin-top: calc(0.25 * var(--logo-height));
    height: calc(0.5 * var(--logo-height));
    div.columns {
      height: 100%;
      align-content: center;
      div.column {
        align-self: center;
        h1 {
          margin-left: 1rem;
        }
      }
    }
  }
  &-center {
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}
* {
  box-sizing: border-box;
}
.about {
  margin-top: 350px;
  display: inline-block;
  &-fb,&-pp {
    width: 300px;
    background-color: white;
    border: 1px solid white;
    border-radius: 15px;
    display: inline-block;
  }

}
.knows-picker {
  color: #7a8288;
  background-color: #fff;
  border: 1px solid #f2f3f3;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.125);
}




// Room Styles
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;

  @include breakpoint(small){
    flex-direction: column;
    width: 100%;
    > .scroll-card {
      max-width: 100%;
      margin: 0;
      padding: 0;
      overflow-x: visible;
    }
  }
  > .scroll-card {
    display: flex;
    flex: 0 0 auto;
  }
}
// Font Awesome Icons
.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    opacity: 0.7;
  }
  &-facebook, &-facebook-square {
    background: #3B5998;
    color: white;
  }
  &-twitter {
    background: #55ACEE;
    color: white;
  }
}

.navbar {
  z-index: 50;
  height: calc(0.75 * var(--logo-height));
  &-section, &-center {
    z-index: 50;
  }
}
// Home Page Layout

.pimp {
  padding-top: 150px;
  display: flex;
  flex-direction: row;
  div {
    width: 50%;
    background-color: rgba(255,255,255,0.8);
    border-radius: 4px;
    margin: 0 4% 0 4%;
    padding: 4px 4px 4px 4px;
  }
}

.clear { clear: both; }

.list {
  background-image: var(--sidegod);
  ul {
    list-style-type: none;
  }
  h3 { border-bottom: solid 1px white; }
  ol {
    margin-top: none;
    column-count: 2;
    li {
      list-style-type: decimal-leading-zero;
      list-style-position: inside;
      break-inside: avoid;
      page-break-inside: avoid;
    }
  }
}

.header-image {
  height: 125px;
}

.page__inner-wrap, #main {
  background-color: $background-color;
  p, h1, h2, h3, h4, h5, h6 {
    color: black;
  }
}

.pagination {
  display: inline-block;
  text-align: center;
  background-color: var(--color-red-light);
  width: 100%;
  margin: 0 auto 0 auto;

  span,a {

  }

  span {
    color: #fff;
    //text-shadow: 1px 0px 20px white;
    text-decoration: none;
    font-size: 1.2rem;

    -webkit-stroke-width: 5.3ps;
    -webkit-stroke-color: #FFFFF;
    -webkit-fill-color: #FFFFFF;

    &.page_number { margin: auto 1rem auto 1rem; }
  }

  a {
    padding: 0;
    margin: 10px 0 10px 0;
    font-size: 1.2em;
    opacity: 0.75;
    color: #ccc;
    font-size: 1.2rem;
    transition: opacity 0.1s;

    &:hover, &#active {
      opacity: 1;
      color: #fff;
      text-shadow: 1px 0px 20px white;
      text-decoration: none;
      -webkit-stroke-width: 5.3ps;
      -webkit-stroke-color: #FFFFF;
      -webkit-fill-color: #FFFFFF;
    }
    &:active, &:focus {
      text-decoration: none;
    }
  }
}
