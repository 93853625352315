@mixin breakpoint($point) {
  @if $point == verywide {
    @media (min-width: 70em) { @content ; }
  }
  @else if $point == wide {
    @media (min-width: 64em) { @content ; }
  }
  @else if $point == notwide {
    @media (min-width: 50em) { @content ; }
  }
  @if $point == 1024 {
    @media (max-width: 1024px)  { @content ; }
  }
  @else if $point == small {
    @media (max-width: 768px)  { @content ; }

  }
}

@mixin minwidth($point) {
  @media (min-width: $point) { @content ; }
}

@mixin maxwidth($point) {
  @media (max-width: $point) { @content ; }
}

/* EXAMPLE USAGE */
/*
.podcasts {
margin: 1em auto;
@include breakpoint(phablet){
width: 100%;
}
@include breakpoint(desktop){
// maybe some desktop layout styles.
}
}
 */
