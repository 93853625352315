body {
  text-align: center;
}

.gallery .card {
  height: 336px;
}

.masthead__inner-wrap {
  padding: 0;
}
.site-logo img {
  max-height: 4rem;
}
#site-nav, .masthead__menu {
  background-color: var(--color-red-light);
}

#wrapper {
  #sidebar-wrapper, #page-content-wrapper {
    margin-top: 100px;
  }
}
